export const Countries = [
  { code: "FJ", name: "Fiji" },
  { code: "NZ", name: "New Zealand" },
  { code: "PN", name: "Pitcairn" },
  { code: "AU", name: "Australia" },
  { code: "CX", name: "Christmas Island" },
  { code: "CC", name: "Cocos Islands" },
  { code: "GB", name: "United Kingdom" },
  { code: "GG", name: "Guernsey" },
  { code: "JE", name: "Jersey" },
  { code: "IM", name: "Isle of Man" },
  { code: "IE", name: "Ireland" },
  { code: "NL", name: "Netherlands" },
  { code: "US", name: "United States" },
  { code: "FR", name: "France" },
  { code: "CL", name: "Chili" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "KH", name: "Cambodia" },
  { code: "VN", name: "Vietnam" },
  { code: "ZA", name: "South Africa" },
  { code: "CW", name: "Curacao" },
  { code: "BL", name: "Saint Barthelemy" },
  { code: "MF", name: "Saint Martin" },
  { code: "GP", name: "Guadalupe" },
  { code: "PF", name: "French Polynesia" },
  { code: "MQ", name: "Martinique" },
  { code: "GF", name: "French Guiana" },
  { code: "RE", name: "Réunion" },
  { code: "YT", name: "Mayotte" },
  { code: "NC", name: "New Caledonia" },
  { code: "PM", name: "Saint Pierre and Miquelon" },
  { code: "WF", name: "Wallis and Futuna" },
  { code: "AT", name: "Austria" },
  { code: "IT", name: "Italy" },
  { code: "CH", name: "Switzerland" },
  { code: "ES", name: "Spain" },
  { code: "AD", name: "Andorra" },
  { code: "MC", name: "Monaco" },
  { code: "DE", name: "Germany" },
  { code: "BE", name: "Belgium" },
  { code: "LU", name: "Luxembourg" },
  { code: "BR", name: "Brazil" },
  { code: "SR", name: "Suriname" },
  { code: "SG", name: "Singapore" },
  { code: "HK", name: "Hong Kong" },
  { code: "SY", name: "Syrien" },
  { code: "UA", name: "Ukraine" },
  { code: "BG", name: "Bulgaria" },
  { code: "DK", name: "Denmark" },
  { code: "EE", name: "Estonia" },
  { code: "FI", name: "Finland" },
  { code: "GR", name: "Greece" },
  { code: "HR", name: "Croatia" },
  { code: "LV", name: "Latvia" },
  { code: "LT", name: "Lithuania" },
  { code: "MT", name: "Malta" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "RO", name: "Romania" },
  { code: "SE", name: "Sweden" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "CZ", name: "Czechia" },
  { code: "HU", name: "Hungary" },
  { code: "CY", name: "Cyprus" },
  { code: "IS", name: "Iceland" },
  { code: "LI", name: "Liechtenstein" },
  { code: "NO", name: "Norway" },
  { code: "AL", name: "Albania" },
  { code: "ME", name: "Montenegro" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "MD", name: "Moldova" },
  { code: "MK", name: "North Macedonia" },
  { code: "RS", name: "Serbia" },
  { code: "TR", name: "Turkey" },
  { code: "CA", name: "Canada" },
  { code: "MX", name: "Mexico" }
];
